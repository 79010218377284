import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import './Navigation.css';  // Archivo CSS para personalizar estilos

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getClassName = (path) => {
    return location.pathname === path ? 'nav-link active-nav-link' : 'nav-link';
  };

  const handleNavigation = (path) => {
    navigate(path, { state: { from: location.pathname } });
  };

  return (
    <Navbar bg="light" expand="lg" className="custom-navbar">
      <Navbar.Brand className="d-lg-none">Beth Yosef</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto custom-nav">
          <NavLink to="/" className={getClassName('/')}>Inicio</NavLink>
          <Nav.Link onClick={() => handleNavigation('/orden-creada')} className={getClassName('/orden-creada')}>Orden Creada</Nav.Link>
          <Nav.Link onClick={() => handleNavigation('/en-proceso')} className={getClassName('/en-proceso')}>En Proceso</Nav.Link>
          <Nav.Link onClick={() => handleNavigation('/preparando-pedido')} className={getClassName('/preparando-pedido')}>Preparando pedido</Nav.Link>
          <Nav.Link onClick={() => handleNavigation('/listo-recoleccion')} className={getClassName('/listo-recoleccion')}>Listo y en espera de recolección</Nav.Link>
          <Nav.Link onClick={() => handleNavigation('/pagado-recoleccion')} className={getClassName('/pagado-recoleccion')}>Pagado y en espera de recolección</Nav.Link>
          <Nav.Link onClick={() => handleNavigation('/pagado-entrega')} className={getClassName('/pagado-entrega')}>Pagado y en proceso de envío</Nav.Link>
          <Nav.Link onClick={() => handleNavigation('/en-ruta')} className={getClassName('/en-ruta')}>En Ruta</Nav.Link>
          <Nav.Link onClick={() => handleNavigation('/entregado')} className={getClassName('/entregado')}>Entregado</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
