import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home';
import Orders from './components/Orders';
import OrderDetails from './components/OrderDetails';
import Navigation from './components/Navigation';

const App = () => {
  const navigate = useNavigate();
  const [isForbidden, setIsForbidden] = useState(false);

  useEffect(() => {
    const referer = document.referrer;
    const allowedReferer = process.env.REACT_APP_ALLOWED_REFERER;
    const enableReferal = process.env.REACT_APP_ENABLE_REFERAL === 'true';

    // Solo ejecutar la lógica si REACT_APP_ENABLE_REFERAL es true
    if (enableReferal && referer !== allowedReferer) {
      setIsForbidden(true);
      navigate('/forbidden'); // Navigate to a forbidden page or handle accordingly
    }
  }, [navigate]);

  return (
    <div>
      {!isForbidden && <Navigation />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/orden-creada" element={<Orders statusId={7} title="Orden Creada" />} />
        <Route path="/en-proceso" element={<Orders statusId={1} title="En Proceso" />} />
        <Route path="/preparando-pedido" element={<Orders statusId={9} title="Preparando pedido" />} />
        <Route path="/listo-recoleccion" element={<Orders statusId={12} title="Listo y en espera de recolección" />} />
        <Route path="/pagado-recoleccion" element={<Orders statusId={8} title="Pagado y en espera de recolección" />} />
        <Route path="/pagado-entrega" element={<Orders statusId={11} title="Pagado y en proceso de envío" />} />
        <Route path="/en-ruta" element={<Orders statusId={2} title="En Ruta" />} />
        <Route path="/entregado" element={<Orders statusId={10} title="Entregado" />} />
        <Route path="/order/:id" element={<OrderDetails />} />
        <Route path="/forbidden" element={<h6>Access Denied</h6>} />
      </Routes>
    </div>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Router>
    <App />
  </Router>
);
