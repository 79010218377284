import React from 'react';
import './Home.css'; // Importa el archivo CSS para estilos personalizados
import logo from '../images/logo-beth-yosef.png'; // Importa la imagen del logo

const Home = () => {
  return (
    <div className="container mt-4 home-container">
      <div className="logo-container">
        <img src={logo} alt="Beth Yosef" className="logo-image" />
      </div>
      <div className="info-container">
        <div className="info-box">
          <h2>Dominio</h2>
          <p>bethyosef.mx</p>
        </div>
        <div className="info-box">
          <h2>URL</h2>
          <p><a href="https://bethyosef.mx" target="_blank" rel="noopener noreferrer">https://bethyosef.mx</a></p>
        </div>
      </div>
    </div>
  );
};

export default Home;
